.Ads {
    background: white;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
    overflow: hidden;
    padding: 0 15px;
}

.AdsWrapper {
    padding: 0 15px;
}

.AdsWrapper h1 {
    margin: 0;
}

.Ads h2,
.UserAds h2 {
    padding: 0 10px!important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
}

.Ads h2 a,
.UserAds h2 a {
    font-size: 13px;
    color: #383838;
    font-weight: bold;
}

.AdsImage {
    position: relative;
}

.AdsImg {
    width: 100%;
    height: 170px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--light-gray-color);
}

.AdsImg a {
    display: block;
}

.AdsImages {
    height: 100%!important;
}

.AdsImgDefault {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdsToolsTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AdsQuickView {
    width: 100%;
    min-height: 35px;
    border-bottom: 1px solid #e3e3e3;
    padding: 0 5px;
}

.QuickImage {
    height: 200px;
    overflow: hidden;
    position: relative;
}


.AdsBookmark {
    font-size: 22px;
    cursor: pointer;
}

.AdsBookmarkActive {
    color: red
}

.AdsType {
    font-size: 12px;
    padding: 0 5px;
    color: #fff;
    margin-right: 10px;
    border-radius: 3px;
}

.AdsTypeNew {
    background: #2196F3!important;
}

.AdsTypeUsed {
    background: #607D8B!important;
}

.AdsTypeService {
    background: #eca013!important;
}

.AdsStatus {
    margin-right: 10px;
}

.AdsRating {
    background: #607D8B;
    color: white;
    font-size: 10px;
    padding: 1px 5px 0 5px;
    border-radius: 3px;
    margin-left: 10px;
    text-align: center;
    white-space: nowrap;
}

.AdsDetails {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AdsTime {
    color: #949494;
    display: flex;
    align-items: center;
}

.AdsTime span {
    display: inline-block;
    font-size: 18px;
    margin: 0 0 0 5px;
}

.AdsTime time {
    font-size: 12px;
}

.AdsCat {
    display: flex;
    align-items: center;
}

.AdsCat a {
    font-size: 12px;
    color: #949494;
    display: inline-flex;
    align-items: center;
}

.AdsCat a::before {
    font-size: 20px;
    color:#9c9c9c;
    padding-left: 5px;
    font-family: 'Line Awesome Free';
    font-weight: 600;
}

.AdsCat a[data-id="1"]::before {
    content:"\f1b9";
}
.AdsCat a[data-id="2"]::before {
    content:"\f10b";
}
.AdsCat a[data-id="3"]::before {
    content:"\f015";
}
.AdsCat a[data-id="4"]::before {
    content:"\f4b8";
}
.AdsCat a[data-id="5"]::before {
    content:"\f7b6";
}
.AdsCat a[data-id="6"]::before {
    content:"\f7d9";
}
.AdsCat a[data-id="7"]::before {
    content:"\f64f";
}
.AdsCat a[data-id="8"]::before {
    content:"\f553";
}
.AdsCat a[data-id="9"]::before {
    content:"\f77d";
}
.AdsCat a[data-id="10"]::before {
    content:"\f12e";
}
.AdsCat a[data-id="12"]::before {
    content:"\f818";
}
.AdsCat a[data-id="14"]::before {
    content:"\f275";
}
.AdsCat a[data-id="15"]::before {
    content:"\f630";
}
.AdsCat a[data-id="16"]::before {
    content:"\f807";
}
.AdsCat a[data-id="17"]::before {
    content:"\f2b5";
}

.AdsCat a:first-child::after,
.AdsLocationList a:first-child::after {
    content: "/";
    display: inline-block;
    margin: 0 4px;
}

.AdsBottom {
    border-top: 1px solid #e3e3e3;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
}

.AdsPrice {
    color: #4CAF50;
    font-size: 15px;
}

.AdsPrice span {
    font-size: 12px;
}

.AdsLocation a {
    font-size: 12px;
    color: #949494;
    display: inline-block;
}

.AdsLocation span {
    font-size: 18px;
    color: #949494;
    margin: 0 0 0 5px;
    display: flex;
}

.AdsLocation {
    display: flex;
    align-items: center;
}

@media all and (max-width: 767px){
    .AdsWrapper {
        padding: 0;
    }
    .AdsToolsTop {
        margin-top: 15px;
    }
}
.NewAds {
  background: white;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 25px;
  position: relative;
}

.AuthForm {
  width: 500px;
  padding: 0 15px;
}

.AuthTitle {
  font-size: 24px;
  margin-bottom: 25px;
}

.Sidebar {
  min-height: 100vh;
  position: fixed!important;
  right: 0!important;
  top: 0px!important;
  overflow-y: auto!important;
  height: 100%!important;
  padding: 50px 0!important;
}

.SidebarUser {
  color: var(--main-color);
  text-align: center;
  margin-bottom: 50px;
}

.SideBarMenu {
  padding-bottom: 50px;
}

.Box {
  background: white;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 0 15px;
}

.NewAds h6 {
  font-size: 14px;
  font-weight: bold;
  margin: 15px 0;
}

.Required::after {
  content: "*";
  color: red;
  margin-right: 5px;
}

.Label {
  font-size: 14px;
  color: gray;
  padding-right: 5px;
  margin: 10px 0;
  display: block;
}

.Hint {
  font-size: 12px;
  padding: 5px;
  color: var(--gray-color)
}

.Counter {
  color: #ffffff;
  font-size: 11px;
  background: #afafaf;
  padding: 2px 4px 2px 4px;
  border-radius: 3px;
  transition: .3s all ease;
}

.Counter.Overflow {
  background: #ff4444!important;
}

.customIcon {
  font-size: 22px;
}

.Image {
  cursor: pointer;
  width: 80px;
  height: 80px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #404040;
  border-radius: 5px;
  overflow: hidden;
  margin:  0 0 10px 10px;
  position: relative;
}

.Image img {
  width: 100%;
  height: 100%;
}

.ImageTrash {
  font-size: 30px;
  color: #ff4444;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 25%);
}

.ImageAdd {
  color: #404040;
  font-size: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.Image::after {
  content: "";
  width: 50px;
  height: 50px;
  background: rgb(0 0 0 / 20%);
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  transition: .5s all ease;
  z-index: 1;
}

.Image i {
  position: relative;
  z-index: 2;
}

.ImageActive::after {
  transform: scale(1)!important;
}

.GlobalTitle h2 {
  font-weight: bold;
  font-size: 20px;
}

/**/


.Main {
  background: #f7f7f7;
  padding: 15px;
  min-height: calc(100vh - 152px);
}

.MainContent {
  transition: .3s all ease;
}

.Toastify * {
  font-family: IranSans!important;
}

.Toastify__toast-body {
  text-align: right;
}

.letterspacing {
  letter-spacing: 5px;
}

aside .logotype {
  width: 100px;
  height: 50px;
}

.copyright {
  text-align: center;
  color: gray;
  font-size: 13px;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.page-link {
  margin: 0 5px;
  border-radius: 5px!important;
  display: flex!important;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px!important;
  color: #a3a3a3!important;
}

li.page-item.active .page-link {
  color: white!important;
  background: #ff4444;
  border-color: #ff4444!important;
}

.promote-text {
  color: #9e9e9e;
  margin: 5px 23px 19px 0!important;
  font-size: 13px;
}

.EditProfile {
  background: white;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 25px;
}

.SellerLogo {
  position: relative;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SellerLogo::before {
  content: "SELLER";
  position: absolute;
  background: var(--main-color);
  color: #fff;
  font-size: 9px;
  font-style: italic;
  padding: 3px 5px;
  border-radius: 4px;
  top: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  left: -55px;
  height: auto;
  line-height: normal;
  direction: ltr;
}

.VideoProcessing {
  background: rgb(0 0 0 / 85%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #404040;
}

@media all and (max-width: 767px){
  .Main,
  .AuthForm {
    width: 100%;
  }
  .NewAds {
    padding: 15px;
  }
}